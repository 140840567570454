import * as React from "react"
import { inject, observer } from "mobx-react"
import { RouteComponentProps } from "react-router"
import BrandStore from "stores/BrandStore"
import LocationSummary from "models/LocationSummary"
import ScheduleStore, { FilterKey } from "apps/book/stores/ScheduleStore"
import APILoader from "components/wrappers/APILoader"
import Svg from "components/Svg"
import ScheduleEntry from "models/ScheduleEntry"
import sortBy from "lodash/sortBy"
import uniqBy from "lodash/uniqBy"
import GenericSummary from "models/GenericSummary"
import * as cx from "classnames"
import * as moment from "moment"
import BookButton from "apps/book/components/BookButton"
import capitalize from "lodash/capitalize"
import PageTracker from "components/wrappers/PageTracker"
import QueryStringMonitor from "apps/book/stores/QueryStringMonitor"
import ScheduleWeekNav from "apps/book/components/ScheduleWeekNav"
import ScheduleDayNav from "apps/book/components/ScheduleDayNav"
import ScheduleHeader from "apps/book/components/ScheduleHeader"
import ExpandedEntryStore from "../stores/ExpandedEntryStore"
import PurchasesStore from "apps/account/stores/PurchasesStore"
import BookingScheduleMenu from "apps/book/components/BookingScheduleMenu"
import ServiceMembershipsCard from "apps/book/components/ServiceMembershipsCard"

export interface Props extends RouteComponentProps {
  store?: BrandStore
  locationSummary: LocationSummary
  scheduleStore: ScheduleStore
  onBookingCheck: (entry: ScheduleEntry) => (e: ButtonEvent) => void
}

// Schedule, current just for a single location
@inject((store: BrandStore) => ({ store }))
@observer
export default class SchedulePage extends React.Component<Props, {}> {
  purchasesStore = new PurchasesStore(this.props.store!)
  private expandedEntryStore = new ExpandedEntryStore(this.props.scheduleStore)
  private qsMonitor = new QueryStringMonitor(
    this.props.scheduleStore,
    this.props.store!,
    this.expandedEntryStore
  )

  componentDidMount() {
    this.purchasesStore.fetch()
  }

  componentWillUnmount() {
    this.qsMonitor.dispose()
    this.expandedEntryStore.dispose()
    this.purchasesStore.dispose()
  }

  waitlistAcceptable = (startsAt: any, waitlistUntil: any) => {
    return moment().isBefore(startsAt) && moment().isAfter(waitlistUntil)
  }

  handleFilterChange = (key: FilterKey) => (e: SelectEvent) => {
    const { store } = this.props
    const { value } = e.currentTarget

    this.props.history.push(store!.routingStore.mergeQuery({ [key]: value }))
  }

  getSelectOptions = (key: string, placeholder: string) => {
    const { entries } = this.props.scheduleStore
    let result = uniqBy(
      entries.map((entry: ScheduleEntry) => entry[key]),
      "name"
    ).reduce(
      (options, op: GenericSummary) => {
        if (op) {
          options.push(
            <option value={op.name} key={op.name}>
              {op.name}
            </option>
          )
        }
        return options
      },
      [
        <option key={entries.length} value="">
          {placeholder}
        </option>,
      ]
    )
    return result
  }

  setExpandedEntry = (id: string) => (e: React.MouseEvent<HTMLElement>) => {
    this.expandedEntryStore.toggleExpandedEntry(id)
  }

  capacityText(entry: ScheduleEntry): string | null {
    const { copy, settings } = this.props.store!.brandData
    const {
      booking,
      canWaitlist,
      freeSpots = 0,
      isFull,
      isOver,
      spotsString,
      waitlistSize,
      hasWaitlist,
      startsAt,
      waitlistUntil,
    } = entry

    if (booking || isOver || freeSpots > settings.freeSpotsThreshold) {
      return null
    }

    if (settings.allowBookingOnWaitlistClosed && moment().isAfter(entry.startsAt)) {
      return ""
    }

    if (isFull) {
      if (!hasWaitlist) {
        return ""
      }

      if (canWaitlist && typeof waitlistSize !== "undefined") {
        return `${(waitlistSize && `${waitlistSize} on`) ||
          "Be First on"} Waitlist`
      }

      if (settings.allowBookingOnWaitlistClosed && hasWaitlist && this.waitlistAcceptable(startsAt, waitlistUntil)) {
        return "Waitlist Closed"
      }
      return `${capitalize(copy.class)} is Full`
    }
    return spotsString
  }

  render() {
    const { locationSummary, scheduleStore, store } = this.props
    const instructorOptions = this.getSelectOptions(
      "instructor",
      `${store!.copy.instructor}`
    )

    const classCategoryOptions = this.getSelectOptions(
      "classCategory",
      `All ${capitalize(store!.copy.classes)}`
    )

    return (
      <>
        {/* TODO: move this up so it doesn't flicker when changing schedule type */}
        <ScheduleHeader locationSummary={locationSummary} key="head" />
        {store!.settings.serviceBooking && (
          <>
            <ServiceMembershipsCard store={store!} purchasesStore={this.purchasesStore} />
            <BookingScheduleMenu
              locationSummary={locationSummary}
              copy={store!.copy}
            />
          </>
        )}
        <PageTracker name="class schedule" />

        <div className="row form-inline mb-3 slide-in delay-3">
          <div className="col">
            <strong>Filter By:</strong>
            <select
              id="classCategories"
              className={cx(
                "schedule-filter",
                "form-control",
                "custom-select",
                "mt-2",
                "mt-lg-0",
                "ml-sm-4"
              )}
              value={scheduleStore.filters.classCategory}
              onChange={this.handleFilterChange("classCategory")}
            >
              {classCategoryOptions}
            </select>
            <select
              id="instructors"
              className={cx(
                "schedule-filter",
                "form-control",
                "custom-select",
                "mt-2",
                "mt-lg-0",
                "ml-sm-4"
              )}
              value={scheduleStore.filters.instructor}
              onChange={this.handleFilterChange("instructor")}
            >
              {instructorOptions}
            </select>
          </div>
        </div>

        <APILoader
          apiStore={scheduleStore}
          alreadyLoaded={scheduleStore.week.loaded}
          key={scheduleStore.week.startDate}
          elementSize="page"
          render={() => {
            const { filteredEntries: entries, week } = scheduleStore

            const dayEntries = sortBy(entries, "startsAt").filter(
              entry => entry.startsAt.format("YYYY-MM-DD") === week.date
            )

            return (
              <>
                <ScheduleWeekNav week={week} className="mt-5 mb-4 mb-lg-5" />

                <ScheduleDayNav
                  week={week}
                  pathname={this.props.location.pathname}
                  search={this.props.location.search}
                  styleClasses={store!.styleClasses}
                />

                <div className="row">
                  <table className="col table schedule-table">
                    <tbody className="schedule-tbody rows-in">
                      {!dayEntries.length && (
                        <tr className="schedule-tr">
                          <td>
                            <div>
                              <h3 className="mt-5">{`It looks like there are no ${
                                store!.copy.classes
                              } available`}</h3>
                              <p className="col-sm-8 p-0">
                                {` Please filter by different criteria to display available ${
                                  store!.copy.classes
                                } at ${store!.locStore.currentLocation!.name}.`}
                              </p>
                            </div>
                          </td>
                        </tr>
                      )}

                      {dayEntries.map((entry, index) => {
                        const isDescriptionExpanded =
                          this.expandedEntryStore.expandedEntryId === entry.id
                        const { copy } = this.props.store!

                        return (
                          <React.Fragment key={`SchedulePage:${entry.id}`}>
                            <tr className="schedule-tr">
                              <td
                                className={cx(
                                  "schedule-classname py-4",
                                  { "has-description" : entry.description }
                                  // breaks position of carets relative to
                                  // other columns :(
                                  // {
                                  // "pb-3": !!entry.subtitle,
                                  // "pb-4": !entry.subtitle,
                                  // }
                                )}
                                onClick={entry.description ? this.setExpandedEntry(entry.id) : undefined}
                                role="button"
                                tabIndex={Number(0)}
                                onKeyDown={(e) => {
                                  if (e.key === "Enter" || e.key === " ") {
                                    e.preventDefault();
                                    if (entry.description) {
                                      this.setExpandedEntry(entry.id);
                                    }
                                  }
                                }}
                              >
                                <div
                                  className={cx(
                                    "d-flex",
                                    "flex-wrap",
                                    "justify-content-between",
                                    "align-items-center"
                                    // "text-nowrap" // XPO-884
                                  )}
                                >
                                  <div>
                                    <h3 className="m-0">{entry.title}</h3>
                                    {entry.subtitle && (
                                      <div>
                                        <strong>{entry.subtitle}</strong>
                                      </div>
                                    )}
                                  </div>

                                  {entry.description && (
                                    <Svg
                                      className=""
                                      name={
                                        isDescriptionExpanded
                                          ? "caret-up"
                                          : "caret-down"
                                      }
                                      size="16"
                                    />
                                  )}

                                  <div
                                    className={cx(
                                      "schedule-inline-description",
                                      "mt-3",
                                      "w-100",
                                      "text-wrap",
                                      !isDescriptionExpanded && "hide"
                                    )}
                                  >
                                    {entry.description}
                                  </div>
                                </div>
                              </td>
                              <td className="schedule-time py-1 py-md-4 align-middle">
                                {entry.startTime}&ndash;{entry.endTime}
                              </td>
                              <td className="schedule-instructor py-1 py-md-4">
                                {entry.instructor ? entry.instructor.name : "No Instructor"}
                              </td>
                              <td className="schedule-spots py-1 py-md-4 text-right">
                                <span className="h5 m-0">
                                  {this.capacityText(entry)}
                                </span>
                              </td>
                              <td className="schedule-actions py-4 pl-2 text-right">
                                <BookButton
                                  className="px-0"
                                  entry={entry}
                                  copy={copy}
                                  onClick={this.props.onBookingCheck(entry)}
                                />
                              </td>
                            </tr>
                            {(isDescriptionExpanded && entry.description) && (
                              <tr
                                className={cx(
                                  "schedule-row-description",
                                  "no-animate",
                                  "drop-in",
                                  "duration-75",
                                  !isDescriptionExpanded && "hide"
                                )}
                              >
                                <td
                                  colSpan={5}
                                  className={cx("border-top-0", "pt-0", "pb-4")}
                                >
                                  {entry.description}

                                  {debugUI && (
                                    <>
                                      <br />
                                      {entry.id} - {entry.clubreadyId} -{" "}
                                      {entry.startsAt.toISOString()} -{" "}
                                      {entry.freeSpots} / {entry.totalSpots}
                                      {entry.booking && (
                                        <>
                                          {""} - {entry.booking.status} -
                                          {(entry.booking as any).scheduleEntry.startsAt.toISOString()}
                                        </>
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            )
          }}
        />
      </>
    )
  }
}
