import React, { Component } from "react"
import { observable } from "mobx"
import { inject, observer } from "mobx-react"
import cx from "classnames"
import Fuse from "fuse.js"

// import LocationMap from
import SearchBar from "apps/location_finder/components/SearchBar"
import BrandStore from "stores/BrandStore"
import LocationList from "./LocationList"
import LocationSummary from "models/LocationSummary"
import LocationFinderStore from "apps/location_finder/stores/LocationFinderStore"

import Svg from "components/Svg"
import LocationsStore from "stores/LocationsStore"
import Spinner from "components/Spinner"

import ModuleLoader from "components/wrappers/ModuleLoader"
import stateNames from "helpers/stateNames"

export interface Props {
  store: BrandStore
  modalLocationsStore: LocationsStore
  onSelect(locationSummary: LocationSummary): void
}

@observer
export default class LocationsModal extends Component<Props> {
  @observable filter: string = ""
  locationFinderStore: LocationFinderStore = new LocationFinderStore(
    this.props.modalLocationsStore
  )

  componentDidMount() {
    // is this needed?
    if (this.props.store.userStore.homeLocation) {
      this.locationFinderStore.homeLocationId = this.props.store.userStore.homeLocation!.id
    }
  }

  componentWillUnmount() {
    // this.locationFinderStore.dispose()
  }

  onSearchTermChange = (term: string) => {
    this.filter = term
  }

  onListItemClick = (location: LocationSummary) => {
    this.locationFinderStore.setHighlightedLocationId(location.id)
  }

  getFilteredLocations = (filter: string) => {
    const { orderedLocations } = this.locationFinderStore
    let filteredLocations: LocationSummary[] = orderedLocations

    filteredLocations = orderedLocations.map(loc => {
      loc.stateName = stateNames[loc.state!]
      return loc
    })

    const options: Fuse.FuseOptions<LocationSummary> = {
      shouldSort: true,
      threshold: 0.4,
      location: 0,
      distance: 100,
      maxPatternLength: 20,
      minMatchCharLength: 1,
      keys: ["name", "city", "state", "stateName", "zip"],
    }
    const fuse = new Fuse(orderedLocations, options)
    if (filter.trim() === "") {
      filteredLocations = orderedLocations
    } else {
      filteredLocations = fuse.search(filter)
    }

    return filteredLocations
  }

  render() {
    return (
      <>
        <div className="row d-flex flex-sm-column flex-lg-row position-relative">
          <div
            className={cx("position-absolute")}
            style={{ top: -6, right: 12, zIndex: 10, cursor: "pointer" }}
            role="button"
            tabIndex={0}
            onClick={() => {
              this.props.store.uiStore.closeModal()
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                e.preventDefault(); // Prevent default behavior for Space key
                this.props.store.uiStore.closeModal();
              }
            }}
            aria-label="Close modal"
          >
            <Svg name="button-close" size="24" />
          </div>
          <div className="col-lg-7 col-xl-8 col-xxl-9">
            <ModuleLoader
              moduleProps={{
                store: this.props.store,
                locationFinderStore: this.locationFinderStore,
              }}
              loadModule={() =>
                import(
                  /* webpackChunkName: "LocationMap" */ "apps/location_finder/components/LocationMap"
                )
              }
            />
            {/* <LocationMap
              store={this.props.store}
              locationFinderStore={this.locationFinderStore}
            /> */}
          </div>
          <div
            className={cx(
              "location-modal__list",
              "col-lg-5",
              "col-xl-4",
              "col-xxl-3",
              "pl-lg-0",
              "mt-4",
              "mt-lg-0"
            )}
          >
            <h2 className="slide-in delay-2">Find a Studio Near You</h2>
            <SearchBar
              onSearchTermChange={this.onSearchTermChange}
              className="slide-in delay-3"
            />
            {this.props.modalLocationsStore.isLoading ? (
              <Spinner />
            ) : (
              <LocationList
                locations={this.getFilteredLocations(this.filter)}
                onListItemClick={this.onListItemClick}
                onLocationSelect={this.props.onSelect}
                store={this.props.store}
                locationFinderStore={this.locationFinderStore}
              />
            )}
          </div>
        </div>
      </>
    )
  }
}
